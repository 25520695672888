.pac {
    &-container {
        border: solid 1px var(--color-grey-2);
        box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.16);
    }
    &-item {
        font-family: var(--font-stack-default);
        font-size: 16px;

        &:hover {
            background-color: var(--color-grey-1);
        }

        &-query {
            font-family: var(--font-stack-default);
            font-size: 16px;
        }
    }
}

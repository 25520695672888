@import "src/assets/scss/mixins";

.cdk-dialog-container {
    &:focus-visible {
        outline: none
    }
}

.add-to-shopping-cart-dialog {
    --mobile-menu-height: 170px;
    position: fixed !important;
    width: 100% !important;
    height: 100% !important;
    max-height: calc(100vh - var(--mobile-menu-height));
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateX(-200px);
    animation: slideIn 0.5s forwards;

    * {
        box-sizing: border-box;
    }

    @include media-breakpoint-up(tablet) {
        max-width: 450px;
        max-height: unset;
        height: 100vh;
        left: unset;
        right: 0;
        top: 0;
        bottom: 0;
    }
}

@keyframes slideIn {
    0% {
        transform: translateX(500px);
    }
    100% {
        transform: translateX(0);
    }
}

// ------------------------------------------------------------------------------
//      Main fonts (Used for body text, labels)
// ------------------------------------------------------------------------------

@font-face {
    font-family: 'Avenir Next';
    src: url('/assets/fonts/avenir-next/regular/AvenirNext-Regular.otf')    format('opentype'),
         url('/assets/fonts/avenir-next/regular/AvenirNext-Regular.woff2')  format('woff2'),
         url('/assets/fonts/avenir-next/regular/AvenirNext-Regular.woff')   format('woff');

    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('/assets/fonts/avenir-next/medium/AvenirNext-Medium.otf')      format('opentype'),
         url('/assets/fonts/avenir-next/medium/AvenirNext-Medium.woff2')    format('woff2'),
         url('/assets/fonts/avenir-next/medium/AvenirNext-Medium.woff')     format('woff');

    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('/assets/fonts/avenir-next/demibold/AvenirNext-DemiBold.otf')      format('opentype'),
         url('/assets/fonts/avenir-next/demibold/AvenirNext-DemiBold.woff2')    format('woff2'),
         url('/assets/fonts/avenir-next/demibold/AvenirNext-DemiBold.woff')     format('woff');

    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('/assets/fonts/avenir-next/bold/AvenirNext-Bold.otf')      format('opentype'),
         url('/assets/fonts/avenir-next/bold/AvenirNext-Bold.woff2')    format('woff2'),
         url('/assets/fonts/avenir-next/bold/AvenirNext-Bold.woff')     format('woff');

    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('/assets/fonts/avenir-next/italic/AvenirNext-Italic.otf')      format('opentype'),
         url('/assets/fonts/avenir-next/italic/AvenirNext-Italic.woff2')    format('woff2'),
         url('/assets/fonts/avenir-next/italic/AvenirNext-Italic.woff')     format('woff');

    font-weight: 400;
    font-stretch: normal;
    font-style: italic;
    font-display: swap;
}

// ------------------------------------------------------------------------------
//      Condensed variants (used for titles / headings)
// ------------------------------------------------------------------------------

@font-face {
    font-family: 'Avenir Next';
    src: url('/assets/fonts/avenir-next/condensed-medium/AvenirNextCondensed-Medium.otf')       format('opentype'),
         url('/assets/fonts/avenir-next/condensed-medium/AvenirNextCondensed-Medium.woff2')     format('woff2'),
         url('/assets/fonts/avenir-next/condensed-medium/AvenirNextCondensed-Medium.woff')      format('woff');

    font-weight: 500;
    font-stretch: condensed;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('/assets/fonts/avenir-next/condensed-demibold/AvenirNextCondensed-DemiBold.otf')     format('opentype'),
         url('/assets/fonts/avenir-next/condensed-demibold/AvenirNextCondensed-DemiBold.woff2')   format('woff2'),
         url('/assets/fonts/avenir-next/condensed-demibold/AvenirNextCondensed-DemiBold.woff')    format('woff');

    font-weight: 600;
    font-stretch: condensed;
    font-style: normal;
    font-display: swap;
}

// ------------------------------------------------------------------------------
//      Serif font (Baskerville italic)
// ------------------------------------------------------------------------------

@font-face {
    font-family: 'Baskerville';
    src: url('/assets/fonts/baskerville/baskerville-Italic.eot');
    src: url('/assets/fonts/baskerville/baskerville-Italic.eot?#iefix')         format('embedded-opentype'),
         url('/assets/fonts/baskerville/baskerville-Italic.woff2')              format('woff2'),
         url('/assets/fonts/baskerville/baskerville-Italic.woff')               format('woff'),
         url('/assets/fonts/baskerville/baskerville-Italic.ttf')                format('truetype'),
         url('/assets/fonts/baskerville/baskerville-Italic.svg#Baskerville')    format('svg');

    font-weight: 400;
    font-stretch: normal;
    font-style: italic;
    font-display: swap;
}


@import 'grid/mixins';

html, body {
    scroll-behavior: smooth;
}

body {
    margin: 0;

    &:has(.block-page-scroll-on-focus:focus),
    &.no-scroll {
        overflow: hidden;
    }
}

input, button, select, optgroup, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
}

.line {
    --line-margin: 1.5rem;
    --line-margin-top: var(--line-margin);
    --line-margin-bottom: var(--line-margin);
    --line-color: var(--color-grey-2);

    margin-top: var(--line-margin-top);
    margin-bottom: var(--line-margin-bottom);
    border-bottom: 1px solid var(--line-color);
}

.grecaptcha-badge {
    visibility: hidden;
}

.text-primary {
    color: var(--color-primary);
}

.text-grey-5 {
    color: var(--color-grey-5);
}

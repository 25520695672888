// Breakpoint viewport sizes and media queries.

@function breakpoint-min($name, $breakpoints: $breakpoints) {
    $min: map-get($breakpoints, $name);
    @return if($min != 0, $min, null);
}

// Maximum breakpoints width.
// The maximum value is reduced by 0.02px to work around the limitations of
// `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
@function breakpoint-max($name, $breakpoints: $breakpoints) {
    $max: map-get($breakpoints, $name);
    @return if($max and $max > 0, $max - .02, null);
}

// Media of at least the minimum breakpoints width. No query for the smallest breakpoints.
// Makes the @content apply to the given breakpoints and wider.
@mixin media-breakpoint-up($name, $breakpoints: $breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    @if $min {
        @media (min-width: $min) {
            @content;
        }
    } @else {
        @content;
    }
}

// Media of at most the maximum breakpoints width. No query for the largest breakpoints.
// Makes the @content apply to the given breakpoints and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $breakpoints) {
    $max: breakpoint-max($name, $breakpoints);
    @if $max {
        @media (max-width: $max) {
            @content;
        }
    } @else {
        @content;
    }
}

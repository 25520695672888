:root {
    --color-grey-1: #f5f3f1;
    --color-grey-2: #e3dfdb;
    --color-grey-3: #b7b3a8;
    --color-grey-4: #8a857a;
    --color-grey-5: #585755;
    --color-grey-6: #262624;
    --color-grey-7: #afafaf;
    --color-primary: var(--color-grey-6);
    --color-secondary: var(--color-grey-5);

    --color-positive: #26a745;
    --color-warning: #ffc10a;
    --color-negative: #dc3545;

    --color-white: #ffffff;
    --color-black: #000000;
}

@import 'mixins';

// ------------------------------------------------------------------------------
//      CSS Variables
// ------------------------------------------------------------------------------

:root {
    // Font stacks --------------------------------------------------------------

    --font-stack-default: 'Avenir Next'
    , 'Frutiger'
    , 'Frutiger Linotype'
    , 'Univers'
    , 'Calibri'
    , 'Gill Sans'
    , 'Gill Sans MT'
    , 'Myriad Pro'
    , 'Myriad'
    , 'DejaVu Sans Condensed'
    , 'Liberation Sans'
    , 'Nimbus Sans L'
    , 'Tahoma'
    , 'Geneva'
    , 'Helvetica Neue'
    , 'Helvetica'
    , 'Arial'
    , sans-serif;

    --font-stack-serif: 'Baskerville'
    , 'Constantia'
    , 'Lucida Bright'
    , 'Lucidabright'
    , 'Lucida Serif'
    , 'Lucida'
    , 'DejaVu Serif'
    , 'Bitstream Vera Serif'
    , 'Liberation Serif'
    , 'Georgia'
    , serif;
}

// ------------------------------------------------------------------------------
//      Global font stack declaration
// ------------------------------------------------------------------------------

html, body {
    font-family: var(--font-stack-default);
    color: var(--color-primary);
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-stack-default);
    font-weight: 600;
    font-stretch: condensed;
    text-transform: uppercase;
    color: var(--color-primary);
    margin: 0;

    &:has(.icon) {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &.serif {
        font-family: var(--font-stack-serif);
        font-stretch: normal;
        text-transform: unset;
        font-weight: 400;
        font-style: italic;
    }

    .soft {
        color: var(--color-grey-3);
    }

    &.subheading {
        font-size: 16px;
        line-height: 24px;

        &.serif {
            color: var(--color-grey-4);
            font-size: 20px;
            line-height: 32px;
        }

        &--soft {
            color: var(--color-grey-4);
        }

        &.medium {
            font-weight: 500;
        }

        &.transform-capital {
            text-transform: capitalize;
        }
    }
}

h1 {
    font-size: 32px;
    line-height: 40px;

    &.mobile-h2 {
        font-size: 24px;
        line-height: 32px;
    }
}

h2 {
    font-size: 24px;
    line-height: 32px;

    &.big {
        font-size: 24px;
        line-height: 32px;

        @include media-breakpoint-up(tablet) {
            font-size: 40px;
            line-height: 48px;
        }
    }
}

h3 {
    font-size: 18px;
    line-height: 26px;

    &.serif {
        font-size: 20px;
        line-height: 24px;
    }
}

p, li {
    color: var(--color-grey-5);
    font-size: 14px;
    line-height: 24px;
    margin: 0;
}

label {
    font-size: 16px;
    font-weight: normal;
    color: var(--color-grey-5);
    line-height: 1.5;
    text-transform: none;

    &.small {
        font-size: 14px;
    }

    &.bold {
        font-weight: 600;
        color: var(--color-primary);
    }

    &.semi-bold {
        font-weight: 500;
    }

    &.white-bold {
        color: white;
        font-weight: 600;
    }

    &.dark-grey {
        color: var(--color-grey-6);
    }

    &.product-title {
        text-transform: uppercase;
    }

}

// ------------------------------------------------------------------------------
//      Utilities
// ------------------------------------------------------------------------------

.text-white {
    color: white;
}

.text-center {
    text-align: center !important;
}

.first-letter-uppercase {
    display: inline-block;
    text-transform: lowercase;

    &:first-letter {
        text-transform: uppercase;
    }
}

.stretch {
    font-stretch: condensed;
}

// ------------------------------------------------------------------------------
//      Link reset
// ------------------------------------------------------------------------------

a {
    text-decoration: none;
    color: var(--color-primary);
}

@include media-breakpoint-up(tablet) {

    h1, h2, h3, h4, h5, h6 {
        &.subheading {
            font-size: 18px;
            line-height: 26px;

            &.serif {
                color: var(--color-grey-4);
                font-size: 24px;
                line-height: 40px;
            }

            &--soft {
                color: var(--color-grey-4);
            }
        }
    }

    h1 {
        font-size: 40px;
        line-height: 48px;

        &.mobile-h2 {
            font-size: 40px;
            line-height: 48px;
        }

        &.serif {
            font-size: 48px;
            line-height: 56px;
        }

        .soft {
            color: var(--color-grey-3);
        }
    }

    h2 {
        font-size: 32px;
        line-height: 40px;

        &.serif {
            font-size: 40px;
            line-height: 48px;
        }
    }

    h3 {
        font-size: 24px;
        line-height: 32px;

        &.serif {
            font-size: 32px;
            line-height: 40px;
        }
    }

    p, li {
        font-size: 16px;
        line-height: 28px;

        &.small {
            font-size: 14px;
            line-height: 24px;
        }
    }
}
